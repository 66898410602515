import ApolloClient, { gql } from "apollo-boost";




export const currentMode = "recette"
//export const currentMode = "dev"



/*
Marketplace contract:  0x6114485DC06Bb3cC6bC04f556FA459117a0cAfeF
Marketplace proxy contract:  0x3a8341F539ee503f01F85e58e857d297693C6176
main token contract:  0xCbb59C9D9f5f2abd5a7192cCC469a2557510f84F
main token proxy contract:  0x634ddF22E56afEb6118D83aA242cDacf1a1f8367
*/
const AppParams_dev = {
  MARKET_ADDRESS : '0x3a8341F539ee503f01F85e58e857d297693C6176',
  AppChainId : '0xa869',
  MAIN_TOKEN_CONTRACT_ADDRESS: "0x634ddF22E56afEb6118D83aA242cDacf1a1f8367",
  PAYEMENT_TOKEN_NAME: "USDT",
  ASSET_TOKEN_CONTRACT_ADDRESS: '0x5F5a58850f2b92FC1D14603245ab63E301D46704',
  FEES_REDUCER_TOKEN_CONTRACT_ADDRESS: "0x023be12ee8B09C3c15D15c89DE66F24890803ca5",
  ANT_ERC20_CONTRACT_ADDRESS: '0xd66b8E3685fBc8f98EdEC0810dD27Faeb51bb0F2',
  STAKING_CONTRACT_ADDRESS: '0xf58AaCC929ECe27410400a882748DE97d8a1968e',
  WALLET_HOLDERS_CONTRACT_ADDRESS: '0x37f332915a14E5f0796B9c3aaa19cDf140f41262',
  RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc",
  BLOCK_EXPLORER_URL: 'https://goerli.etherscan.io/',
  NFT_MARKET_GRAPH: "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/o2b-marketplace-goerli",
  STAKING_GRAPH: "https://api.thegraph.com/subgraphs/name/ant-nft-subgraph/ant-staking-dev",
  TOKEN_GRAPH: "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/o2b-nft-goerli",
  IPFS_GATWAY : 'https://ant-finance-dev.mypinata.cloud/ipfs/',
}


/*
Marketplace contract:  0x26556d05Aa70c5D68eEE920ca6ef7FF5491A1DF0
Marketplace proxy contract:  0xb994f0ec4E9D121Ef50911767900d9Ca15d75D3a
main token contract:  0xa71BCf23b5619e0acB8c130AC5db2191f036CAF5
main token proxy contract:  0x322E2EcA072ab5AFcd624D93B562F9AE5669A634
*/
const AppParams_recette = {
  MARKET_ADDRESS : '0xb994f0ec4E9D121Ef50911767900d9Ca15d75D3a',
  AppChainId : '0xa869',
  MAIN_TOKEN_CONTRACT_ADDRESS: "0x322E2EcA072ab5AFcd624D93B562F9AE5669A634",
  PAYEMENT_TOKEN_NAME: "USDT",
  ASSET_TOKEN_CONTRACT_ADDRESS: '0x5F5a58850f2b92FC1D14603245ab63E301D46704',
  FEES_REDUCER_TOKEN_CONTRACT_ADDRESS: "0x023be12ee8B09C3c15D15c89DE66F24890803ca5",
  ANT_ERC20_CONTRACT_ADDRESS: '0xd66b8E3685fBc8f98EdEC0810dD27Faeb51bb0F2',
  STAKING_CONTRACT_ADDRESS: '0xf58AaCC929ECe27410400a882748DE97d8a1968e',
  WALLET_HOLDERS_CONTRACT_ADDRESS: '0x37f332915a14E5f0796B9c3aaa19cDf140f41262',
  RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc",
  BLOCK_EXPLORER_URL: 'https://goerli.etherscan.io/',
  NFT_MARKET_GRAPH: "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/o2b-marketplace-goerli-recette",
  STAKING_GRAPH: "https://api.thegraph.com/subgraphs/name/ant-nft-subgraph/ant-staking-dev",
  TOKEN_GRAPH: "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/o2b-nft-goerli-recette",
  IPFS_GATWAY : 'https://ant-finance-dev.mypinata.cloud/ipfs/',
}
const AppParams_prod = {
  MARKET_ADDRESS : '0x3a8341F539ee503f01F85e58e857d297693C6176',
  AppChainId : '0xa869',
  MAIN_TOKEN_CONTRACT_ADDRESS: "0x634ddF22E56afEb6118D83aA242cDacf1a1f8367",
  PAYEMENT_TOKEN_NAME: "USDT",
  ASSET_TOKEN_CONTRACT_ADDRESS: '0x5F5a58850f2b92FC1D14603245ab63E301D46704',
  FEES_REDUCER_TOKEN_CONTRACT_ADDRESS: "0x023be12ee8B09C3c15D15c89DE66F24890803ca5",
  ANT_ERC20_CONTRACT_ADDRESS: '0xd66b8E3685fBc8f98EdEC0810dD27Faeb51bb0F2',
  STAKING_CONTRACT_ADDRESS: '0xf58AaCC929ECe27410400a882748DE97d8a1968e',
  WALLET_HOLDERS_CONTRACT_ADDRESS: '0x37f332915a14E5f0796B9c3aaa19cDf140f41262',
  RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc",
  BLOCK_EXPLORER_URL: 'https://goerli.etherscan.io/',
  NFT_MARKET_GRAPH: "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/o2b-marketplace-goerli",
  STAKING_GRAPH: "https://api.thegraph.com/subgraphs/name/ant-nft-subgraph/ant-staking-dev",
  TOKEN_GRAPH: "https://api.thegraph.com/subgraphs/name/o2b-nft-marketplace/o2b-nft-goerli",
  IPFS_GATWAY : 'https://ant-finance-dev.mypinata.cloud/ipfs/',
}


function getAppParams(){
  if(currentMode == "dev"){
      return AppParams_dev
  }else if(currentMode == "prod"){
      return AppParams_prod
  }else if(currentMode == "recette"){
      return AppParams_recette
  }
  return AppParams_dev
}

export const AppParams = getAppParams();


export const StakingClient = new ApolloClient({
  uri: AppParams.STAKING_GRAPH
});
export const MarketPlaceClient = new ApolloClient({
  uri: AppParams.NFT_MARKET_GRAPH
});

export const TokensClient = new ApolloClient({
  uri: AppParams.TOKEN_GRAPH
});




export const tokenValue = (isAvax, amount) => {
  return amount * (isAvax ? avaxValue() : antValue());
}
export const avaxValue = () => {
  return 0;
}
export const antValue = () => {
  return 1;
}

export const networks = {
  etheurem: {
    chainId: '0x1'
  },
  goerli: {
    chainId: '0x5'
  }
}
