import { useEffect, useState } from 'react'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import RangeSlider from 'react-bootstrap-range-slider'
import {
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Form,
  Button,
  Spinner
} from 'react-bootstrap'

import { Pagination } from 'antd';
import { useRecoilState } from 'recoil';

import {
  useSearchParams,
} from "react-router-dom";

import { MarketPlaceClient } from '../config'

import NFTCard from './NFTCard'
import Collapsible from 'react-collapsible'
import React from 'react'
import { itemsForSale, itemsForSaleByCategory } from '../services/graphql.js'
import { assetTokenTotalForSale, mainTokenTotalForSale, refreshIndex } from '../services/atoms'
import TokenClassFilter from '../components/TokenClassFilter';

const Nfts = () => {

  let [searchParams, setSearchParams] = useSearchParams();

  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  const [minValue, setMinValue] = useState('0')
  const [maxValue, setMaxValue] = useState('100000')
  const [page, setPage] = useState(0)

  const [nftsList, setNftsList] = useState({ data: [] })

  // Search Filter
  const [filterInput, setFilterInput] = useState('')
  const [selectedOrder, setSelectedOrder] = useState('tokenId-asc')
  const [category, setCategory] = useState('')
  const [visible, setVisible] = React.useState(false);

  const [refresh, setRefresh] = useRecoilState(refreshIndex);


  const [mainTokenTotal, setMainTokenTotal] = useRecoilState(mainTokenTotalForSale);
  const [assetTokenTotal, setAssetTokenTotal] = useRecoilState(assetTokenTotalForSale);
  const [tokenClasses, setTokenClasses] = useState([]);
  const [classesFilter, setClassesFilter] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [dataLoading, setDataLoading] = useState(false);




  const searchText = (event) => {
    setFilterInput(event.target.value)
  }

  const fetchPagesInfos = (res) => {
    let totalElements = 0;
    let categorysInfos = {};
    for (let i = 0; i < res.data.tokenClasses.length; i++) {
      totalElements += parseInt(res.data.tokenClasses[i].numberTokensListed)
      categorysInfos[res.data.tokenClasses[i].id] = res.data.tokenClasses[i].numberTokensListed
    }
    setTokenClasses(categorysInfos);
    setTotalElements(totalElements);
  }


  const remoteData = (query) => {

    setNftsList({ data: [] });
    setDataLoading(true)
    const queryCall = query.category == '' ? itemsForSale : itemsForSaleByCategory;
    return MarketPlaceClient.query({
      query: queryCall,
      fetchPolicy: "network-only",
      variables: {
        category: query.category,
        minPrice: query.minPrice,
        maxPrice: query.maxPrice,
        filter: query.filter,
        offset: page * query.pageSize,
        limit: query.pageSize,
        orderBy: query.orderBy === undefined ? "price" : query.orderBy,
        orderDirection: query.orderDirection === undefined || query.orderDirection === "" ? "asc" : query.orderDirection
      }
    }).then((res) => {
      setNftsList({ data: res.data.nfts })
      setDataLoading(false)
    })
  }

  useEffect(() => {
    remoteData(
      {
        category: category,
        minPrice: minValue,
        maxPrice: maxValue,
        filter: filterInput,
        page: 0,
        pageSize: 12,
        orderBy: selectedOrder.split("-")[0],
        orderDirection: selectedOrder.split("-")[1]
      }
    );
  }, [filterInput, selectedOrder, minValue, maxValue, refresh, page, category])

  useEffect(() => {
    setPage(0)
  },
    [category])

  const paginationChanged = (pageNumber, pageSize) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setPage(pageNumber - 1);
  }


  const sortChanged = (event) => {
    setSelectedOrder(event.target.value)
  }

  if (loadingState === 'loaded' && !nfts.length)
    return <h1 className='px-20 py-10 text-3xl'>No items in marketplace</h1>
  return (
    <div>
      {

        <Row>
          <Col sm={12} md={3} lg={3} xl={2}>




            <h5
              style={{
                paddingTop: 30,

                paddingRight: 20,
                paddingLeft: 20,
              }}
              className="element-hidden"
            >
              Filtre
            </h5>

            <div className="element-hidden">
              <FilterC
                trigger=''
                contenu={
                  <div className='AlignB'>
                    <TokenClassFilter defaultValue={searchParams.get("category")} onClassesFound={(values) => { fetchPagesInfos(values) }} onSelect={(value) => setCategory(value)}></TokenClassFilter>
                  </div>
                }
              />
              <FilterC
                trigger='Price'
                contenu={
                  <Form>
                    <Form.Group as={Row}>
                      <Col xs='6'>
                        <Form.Control value={minValue} onChange={(e) => setMinValue(e.target.value.toString())} />
                      </Col>
                      <Col xs='6'>
                        <Form.Control value={maxValue} onChange={(e) => setMaxValue(e.target.value.toString())} />
                      </Col>
                      <Col xs='6'>
                        <RangeSlider
                          variant="light"
                          tooltip="off"
                          size="sm"
                          value={parseFloat(minValue)}
                          onChange={(e) => setMinValue(e.target.value.toString())}
                          min={0}
                          max={10}
                        />
                      </Col>
                      <Col xs='6'>
                        <RangeSlider
                          variant="light"
                          tooltip="off"
                          size="sm"
                          value={parseFloat(maxValue)}
                          onChange={(e) => setMaxValue(e.target.value.toString())}
                          min={20}
                          max={1000}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                }
              />
            </div>
          </Col>

          <Col sm={12} md={9} lg={9} xl={10}>
            {/*<Form.Control
              type='text'
              id='searchText'
              onChange={searchText.bind(this)}
              value={filterInput}
              placeholder='Search Item...'
            ></Form.Control>
              */}
            <br />
            <div className="AlignTri">
              <select onChange={sortChanged} value={selectedOrder} >
                <option value='updatedAt-desc'>Latest</option>
                <option value='tokenId-asc'>Lowest ID</option>
                <option value='tokenId-desc'>Highest ID</option>


              </select>
              <div className="filterMobile"><button className="element-hide-l codeD" onClick={() => setVisible(!visible)}>More option</button></div>
            </div>
            {/* Mobile*/}
            <div className="element-hide-l">
              <div className={visible ? 'element-visible' : 'element-hidden'}>
                <FilterC
                  trigger='Class'
                  contenu={
                    <div className='AlignB'>
                      <TokenClassFilter defaultValue={searchParams.get("category")} onClassesFound={(values) => { fetchPagesInfos(values) }} onSelect={(value) => setCategory(value)}></TokenClassFilter>
                    </div>
                  }
                />
                <FilterC
                  trigger='Price'
                  contenu={
                    <Form>
                      <Form.Group as={Row}>
                        <Col xs='6'>
                          <Form.Control value={minValue} onChange={(e) => setMinValue(e.target.value.toString())} />
                        </Col>
                        <Col xs='6'>
                          <Form.Control value={maxValue} onChange={(e) => setMaxValue(e.target.value.toString())} />
                        </Col>
                        <Col xs='6'>
                          <RangeSlider
                            variant="light"
                            tooltip="off"
                            size="sm"
                            value={parseFloat(minValue)}
                            onChange={(e) => setMinValue(e.target.value.toString())}
                            min={0}
                            max={10}
                          />
                        </Col>
                        <Col xs='6'>
                          <RangeSlider
                            variant="light"
                            tooltip="off"
                            size="sm"
                            value={parseFloat(maxValue)}
                            onChange={(e) => setMaxValue(e.target.value.toString())}
                            min={20}
                            max={1000}
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  }
                />
              </div>
              {/*fin tri mobile */}
            </div>

            <Row>
              <Col
                sm={12}
                md={12}
                lg={{ span: 4, offset: 8 }}
                xl={{ span: 4, offset: 8 }}
              >
                <div className='filterItem d-none'>
                  <DropdownButton id='dropdown-item-button' title=' Lowest ID '>
                    <Dropdown.Item
                      as='button'
                      id='priceAsc'
                    /*onClick={() => sortPriceAsc()}*/
                    >
                      Price
                    </Dropdown.Item>
                    <Dropdown.Item as='button'>Class</Dropdown.Item>
                  </DropdownButton>
                  <i className='fa-brands fa-microsoft'></i>
                  <i className='fa-solid fa-list'></i>
                </div>
              </Col>
            </Row>
            <div className="test2">
              <Row>
                {
                  nftsList.data.length > 0 ?
                    nftsList.data.map((nft, index) => (
                      <Col key={index} sm={12} md={6} lg={4} xl={3}>
                        <NFTCard nft={nft} />
                      </Col>
                    ))
                    :
                    dataLoading ?
                      <div style={{ height: '71vh' }} className="centerV">
                        <Spinner style={{ height: "2rem", width: "2rem" }} animation="border" />
                      </div>
                      :
                      <div style={{ height: '70vh'}} className="centerV">
                        <span style={{ verticalAlign: 'middle', fontSize: 18 }}>No assets</span>
                      </div>
                }
              </Row>
              <div>
                <Pagination onChange={paginationChanged} current={(page + 1)} defaultCurrent={(page + 1)} total={category == '' ? totalElements : tokenClasses[category]} />
              </div>
            </div>
          </Col>
        </Row>
      }
    </div >
  )
}

function FilterC({ trigger, contenu }) {
  return (
    <Collapsible open={true} trigger={trigger}>
      <div>{contenu}</div>
    </Collapsible>
  )
}

export default Nfts
