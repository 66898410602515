import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Image, ListGroup, Card, Button } from 'react-bootstrap'
// import Rating from '../components/Rating'
//import products from '../products'
import axios from 'axios'

import {
  AppParams,
  MarketPlaceClient,
  TokensClient
} from '../config'

import { MarketplaceAbi } from '../artifacts/contracts-abis/Marketplace-abi'
import BuyNFT from '../components/BuyNFT'
import { marketplaceItemById, myTokenDetails } from '../services/graphql'

const NFTDetails = () => {
  const { token_id, item_id, contractAddress } = useParams()
  const [nft, setNft] = useState([])

  const [item, setItem] = useState({
    image: '/images/Spin-det.svg',
    name: '--',
    description: '--',
    category: '--',
    tokenURI: '--',
    pdfFile: '--',
  })


  useEffect(() => {
    // Load NFTS
    loadNFTs()
  }, [])

  async function loadNFTs() {
    /*let provider
    if (window.ethereum) {
      provider = new ethers.providers.Web3Provider(window.ethereum)
    } else if (window.web3) {
      provider = new ethers.providers.Web3Provider(window.web3.currentProvider)
    } else {
      console.warn('No Ethereum browser detected! Check out Metamask')
    }

    const marketContract = new ethers.Contract(
      AppParams.MARKET_ADDRESS,
      MarketplaceAbi,
      provider
    )
    marketContract.idMarketItem(item_id).then((data) => {
      let item = {
        //price: ethers.utils.formatUnits(data.token.price.toString(), 'ether'),
        itemId: data.itemId.toNumber(),
        seller: data.seller,
        owner: data.owner
      }

      setNft(item)
    })
*/


    MarketPlaceClient.query({
      query: marketplaceItemById,
      fetchPolicy: 'network-only',
      variables: {
        itemId: parseInt(item_id),
      },
    }).then((res) => {
      let item = {
        price: res.data.nfts[0].tokenClass.price,
        itemId: res.data.nfts[0].itemId,
        seller: res.data.nfts[0].currentSeller,
        owner: res.data.nfts[0].currentSeller
      }

      setNft(item)
    })


    TokensClient.query({
      query: myTokenDetails,
      fetchPolicy: 'network-only',
      variables: {
        contractAddress: contractAddress,
        tokenId: parseInt(token_id),
      },
    }).then((res) => {
      axios.get(res.data.tokens[0].tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY)).then((meta) => {
        let tokensMeta = {
          image: meta.data.image.replace("ipfs://", AppParams.IPFS_GATWAY),
          name: meta.data.name,
          description: meta.data.description,
          owner: res.data.tokens[0].owner.id,
          category: res.data.tokens[0].tokenClass,
          tokenURI: res.data.tokens[0].tokenURI,
          pdfFile: meta.data.pdfFile != null ? meta.data.pdfFile : "--",
        }
        setItem(tokensMeta)
      })
    })
  }

  function showOnExplorer(event) {
    event.stopPropagation()
    window.open(
      `${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${token_id}`,
      '_blanc'
    )
  }

  function circumference(r) {
    if (Number.isNaN(Number.parseFloat(r))) {
      return 0
    }
    return parseFloat(r)
  }

  function downloadURI(uri, tokenClass) {

    fetch(uri)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = tokenClass + '.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })

  }

  return (
    <div className='screenD container'>
      <Link className='btn btn-outline-primary my-2 pl-0' to='/nfts'>
        <i className='fa-solid fa-angle-left'></i> Back
      </Link>
      <Row>
        <Col>
          <span
            className='codeD'
            style={{ cursor: 'pointer' }}
            onClick={showOnExplorer}
          >
            #{token_id}
          </span>
        </Col>
      </Row>
      <Card.Text as='h3'>{nft?.name}</Card.Text>
      <Row className="rowD">
        <Col md={6}>
          {/* <Card.Text as='h3'>{nft?.itemId}</Card.Text> */}

          <div className='p25'>
            <Image
              src={item?.image}
              alt={item?.name}
              style={{ borderRadius: 20 }}
              fluid
            />
          </div>

         

        </Col>
        <Col md={6} className="colS">
      
      <div>
        <h4>Price</h4>
        <ListGroup variant='flush' className='card cardab'>
          <ListGroup.Item>
            <Row>
              <Col>
                <div className='priceD'>
                  <div>
                    {nft.price != null ?
                      <h4>

                        {circumference(nft.price).toLocaleString().replace(",", ".")}{' '} {AppParams.PAYEMENT_TOKEN_NAME}
                      </h4>
                      :
                      <h4>--</h4>
                    }
                  </div>
                  <BuyNFT
                    props={{
                      nftContract: contractAddress,
                      itemId: item_id,
                      price: nft.price,
                      avaxPayement: false,
                    }}
                  ></BuyNFT>
                </div>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </div>
      <div>
        <h4>About</h4>
        <ListGroup variant='flush' className='card cardab'>
          <ListGroup.Item className="priceD">
            Contract <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}`} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{contractAddress}</span></a>
          </ListGroup.Item>
          <ListGroup.Item className="priceD">
            Token ID <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${token_id}`} target="_blank"><span style={{ color: '#BAFF85 !important' }}>{token_id}</span></a>
          </ListGroup.Item>
          <ListGroup.Item className="priceD">
            Owner <a href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${item.owner}`} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{item?.owner == null ? "--" : item?.owner}</span></a>
          </ListGroup.Item>



          <ListGroup.Item className="priceD">
            Class <span>{item?.category}</span>{' '}
          </ListGroup.Item>


        </ListGroup>
      </div>

    
  </Col>
       
      </Row>
      <Row>
        <Col>
        <div>
            <h4>More</h4>
            <ListGroup variant='flush' className='card cardab'>
              <ListGroup.Item className="priceD">
                Token URI <a href={item?.tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY)} target="_blank" className="itemS"><span style={{ color: '#BAFF85 !important' }}>{item?.tokenURI}</span></a>
              </ListGroup.Item>

              <ListGroup.Item className="priceD">
                Project info <a href={item?.pdfFile.replace("ipfs://", AppParams.IPFS_GATWAY)} className="itemS" target="_blank"><span style={{ color: '#BAFF85 !important' }}>{item?.pdfFile}</span></a>

              </ListGroup.Item>
              {/*<div style={{ textAlign: 'center' }}>
                <Button className='download-pdf m-3' variant='primary' onClick={(e) => { downloadURI(item?.pdfFile.replace("ipfs://", AppParams.IPFS_GATWAY), item?.category) }}>
                  Download PDF
  </Button>
              </div>*/}
              <ListGroup.Item>
                <div style={{ textAlign: 'center' }}>Description</div> <br /> <div className="spandesc"><span>{
                  item?.description.includes('$100') ?
                    item?.description.replace('$100', '1').replace(".", "") + " AVAX."
                    : item?.description
                }</span></div>{' '}
              </ListGroup.Item>
            </ListGroup>

          </div>
        </Col>
      </Row>
    </div>
  )
}

export default NFTDetails
